<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="32" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title">账户安全 / <span>修改密码</span></h1>
                </div>

                <div class="shop_form mt30" v-show="type === 1">
                    <el-form ref="form1" :model="form1" :rules="rules" label-width="84px" size="small">
                        <el-form-item label="手机号：" class="mb32">
                            <span class="phone">{{phone.length === 11 ? phone.substr(0, 4) + '****' + phone.substr(7, 4) : phone}}</span>
                        </el-form-item>
                        <el-form-item label="验证码：" class="mb32" prop="code">
                            <el-input v-model="form1.code" placeholder="请输入验证码" class="phoneInput ml0"></el-input>
                            <el-button :class="isSendCode ? 'on' : ''" type="primary" plan class="halfInput" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                            <div class="tip">说明：为保证您的账户安全，需验证您的手机号及验证码，通过后可完成密码修改操作</div>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm1('form1')">验 证</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="shop_form mt30" v-show="type === 2">
                    <el-form ref="form2" :model="form2" :rules="rules" label-width="84px" size="small">
                        <el-form-item label="新密码：" class="mb32" prop="password">
                            <el-input v-model="form2.password" placeholder="8-20位密码，区分大小写" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item label="确认密码：" class="mb32" prop="passwordSure">
                            <el-input v-model="form2.passwordSure" placeholder="确认密码" class="fullInput" show-password></el-input>
                            <div class="tip">说明：请设置密码（8-20位，包含字母和数字）</div>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm2('form2')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getUserInfo, smsSend, smsVerify, postUserPassword} from "../../../api/public";

  const userMenu = () => import('../../../components/layout/shop/userMenu')
  export default {
    name: "shopPassword",
    data () {
      var validatePass2 = (rule, value, callback) => {
        if (value !== this.form2.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        type: 1,
        form1: {
          code: ''
        },
        form2: {
          password: '',
          passwordSure: ''
        },
        rules: {
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$', message: '请设置密码（8-20位，包含字母和数字）', trigger: 'blur'}
          ],
          passwordSure: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }
          ],
        },
        phone: '',
        isRequestSend: false,
        submitLoading: false,
        isSendCode: false,
        smsBtnText: ''
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getUserInfo()
    },
    methods: {
      getUserInfo () {
        getUserInfo().then(res => {
          if (res.code === 0) {
            this.phone = res.data.phone
          }
        })
      },
      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.phone,
            type: 4
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },
      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },
      submitForm1 (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            smsVerify({
              data: {
                phone: this.phone,
                type: 4,
                code: this.form1.code
              }
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.type = 2
                this.$refs['form1'].resetFields()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      submitForm2 (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            postUserPassword({
              data: {
                phone: this.phone,
                password: this.form2.password
              }
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '密码修改成功',
                  showClose: true,
                  type: 'success'
                })
                localStorage.removeItem('access_token_shop')
                this.$router.replace('/login')
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      }
    },
    components: {
      userMenu
    }
  }
</script>
